import jsPDF from "jspdf";
import "jspdf-autotable";
import {  marges2 } from "print/entete.js";
import { numberWithSpace } from "@/utils/functions.js";


  export function dossier(store,data_item,taxe_list,approche_list) {
    
    let columns = [];
   
 
    var doc = new jsPDF();
    let marges = marges2(doc,store);

    let doc_head = [];
    doc_head.push({
      lib1: {
        content: "CONCEPT",
        styles: { fontStyle: "normal", fontSize: 14, halign: "center" },
        colSpan : 2
      },
      val1: "",
      lib2: {
        content: "Dossier d'importation N° "+data_item.nodossier,
        styles: { fontStyle: "bold", fontSize: 12, halign: "center" },  
        colSpan : 4
      },
      val2: "",
      lib3: "",
      val3: "",
    });  
    doc_head.push({
      lib1: {
        content: "Ref. :",
        styles: { fontStyle: "normal", fontSize: 8 },
      },
      val1:  {
        content: data_item.cmd_ref ? data_item.cmd_ref : "",
        styles: { fontStyle: "bold", fontSize: 8 },
      },  
      lib2: {
        content: "Fournisseur",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val2: {
        content: data_item.tier_name,
        styles: { fontStyle: "bold", fontSize: 8 },
      },  
      lib3: {
        content: "Projet",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val3: {
        content: data_item.projet ? data_item.projet : "",
        styles: { fontStyle: "bold", fontSize: 8, halign: "right" },
      }        
    });
    doc_head.push({
      lib1: {
        content: "Facture",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val1: {
        content: data_item.num_facture,
        styles: { fontStyle: "bold", fontSize: 8 },
      },
      lib2: {
        content: "Date Facture",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val2: {
        content: data_item.date_facture,
        styles: { fontStyle: "bold", fontSize: 8 },
      },
      lib3: {
        content: "Montant",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val3: {
        content: numberWithSpace(data_item.montant.toFixed(2))+" "+data_item.devise ,
        styles: { fontStyle: "bold", fontSize: 8, halign: "right" },
      }             
    });
    doc_head.push({
      lib1: {
        content: "Banque Fournisseur",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val1: {
        content: data_item.bank_fournis,
        styles: { fontStyle: "bold", fontSize: 8 },
      },
      lib2: {
        content: "Pays d'origine",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val2: {
        content: data_item.country_origine,
        styles: { fontStyle: "bold", fontSize: 8 },
      },
      lib3: {
        content: "Frêt",
        styles: { fontStyle: "normal", fontSize: 8 },       
      },
      val3: {
        content:(data_item.fret_devise? (numberWithSpace(data_item.fret_devise.toFixed(2))+" "+data_item.devise)
        : data_item.fret_dzd ? numberWithSpace(data_item.fret_dzd.toFixed(2))+" DA" : "" ),
        styles: { fontStyle: "bold", fontSize: 8, halign: "right" },
      }              
    });
    
    let head = [];
    columns = [];
    columns.push({ dataKey: "lib1" });
    columns.push({ dataKey: "val1" });   
    columns.push({ dataKey: "lib2" });
    columns.push({ dataKey: "val2" });
    columns.push({ dataKey: "lib3" });
    columns.push({ dataKey: "val3" });
    doc.autoTable({
      margin: { left: marges.margin_left,top: marges.margin_top , },
      body: doc_head,
      columnStyles: {
        0: { halign: "left", cellWidth: 30 },
        1: { halign: "left", cellWidth: 30 },
        2: { halign: "left", cellWidth: 30 },
        3: { halign: "left", cellWidth: 30,},
        4: { halign: "left", cellWidth: 30,},
        5: { halign: "left", cellWidth: "auto",},
      },
      columns: columns,
      theme: "grid",
      styles: {
        valign: "middle",      
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
        cellPadding: 1,        
      },
      tableWidth: "auto",
    });
   
    //details Dossier
    //Banque-------------------------------
    head=["Domiciliation"]
    doc_head = [];
    doc_head.push({
      lib1: "Banque de domiciliation",
      val1: data_item.agence,      
    });
    doc_head.push({
      lib1: "N° Pré-domiciliation",
      val1: data_item.num_predom,      
    });
    doc_head.push({
      lib1: "Date Pré-domiciliation",
      val1: data_item.date_predom,      
    });
    doc_head.push({
      lib1: "N° Domiciliation",
      val1: data_item.num_dom,      
    });
    doc_head.push({
      lib1: "Date Domiciliation",
      val1: data_item.date_dom,      
    });
   
    columns = [];
    columns.push({ dataKey: "lib1" });
    columns.push({ dataKey: "val1" });      
    doc.autoTable({
      margin: { left: marges.margin_left, },  
      startY:55,   
      headStyles: {
        halign: "left",
        valign: "middle",
        cellPadding:1,
        fillColor: [128, 128, 128],
      },
      head: [head],
      body: doc_head,
      columnStyles: {
        0: { halign: "left", cellWidth: 50 },        
        1: { halign: "left", cellWidth: "auto",},
      },
      columns: columns,
      theme: "striped",
      styles: {
        valign: "middle",      
        fillColor: [255, 255, 255],        
        cellPadding: 1,
        fontSize: 8
      },
      tableWidth: 90,
    }); 
    ///fret--------------------------------
    head=["Frêt"]
    doc_head = [];
    doc_head.push({
      lib1: "Embarquement Prévu",
      val1: data_item.date_embar_prev,      
    });
    doc_head.push({
      lib1: "Embarquement",
      val1: data_item.date_embar,      
    });
    doc_head.push({
      lib1: "Compagnie",
      val1: data_item.company,      
    });
    doc_head.push({
      lib1: "N° Document de Transport",
      val1: data_item.num_doc_transport,      
    });
    doc_head.push({
      lib1: "Navire",
      val1: data_item.navire,      
    });
    doc_head.push({
      lib1: "Provenance",
      val1: data_item.provenance,      
    });
    doc_head.push({
      lib1: "Destination",
      val1: data_item.destination,      
    });
    doc_head.push({
      lib1: "Arrivée Prévue",
      val1: data_item.eta,      
    });
    doc_head.push({
      lib1: "Arrivée",
      val1: data_item.date_debarq,      
    });
    columns = [];
    columns.push({ dataKey: "lib1" });
    columns.push({ dataKey: "val1" });      
    doc.autoTable({
      margin: { left: marges.margin_left,},      
      headStyles: {
        halign: "left",
        valign: "middle",
        cellPadding:1,
        fillColor: [128, 128, 128],
      },
      head: [head],
      body: doc_head,
      columnStyles: {
        0: { halign: "left", cellWidth: 50 },        
        1: { halign: "left", cellWidth: "auto",},
      },
      columns: columns,
      theme: "striped",
      styles: {
        valign: "middle",      
        fillColor: [255, 255, 255],        
        cellPadding: 1,
        fontSize: 8
      },
      tableWidth: 90,
    }); 
    //transit------------------
    head=["Transit"]
    doc_head = [];
    doc_head.push({
      lib1: "Remis au Transitaire le ",
      val1: data_item.date_remise_transit,      
    });
    doc_head.push({
      lib1: "Transitaire",
      val1: data_item.transitaire,      
    });
    doc_head.push({
      lib1: "N° D10",
      val1: data_item.num_D10,      
    });
    doc_head.push({
      lib1: "Date D10",
      val1: data_item.date_D10,      
    });
    doc_head.push({
      lib1: "Taux de change",
      val1: data_item.taux_change,      
    });
    doc_head.push({
      lib1: "N° BE",
      val1: data_item.num_BE,      
    });
    doc_head.push({
      lib1: "Date BE",
      val1: data_item.date_BE,      
    });
    doc_head.push({
      lib1: "N° Franchise",
      val1: data_item.num_franchise,      
    });
    doc_head.push({
      lib1: "Date Franchise",
      val1: data_item.date_franchise,      
    });
    columns = [];
    columns.push({ dataKey: "lib1" });
    columns.push({ dataKey: "val1" });      
    doc.autoTable({
      margin: { left: marges.margin_left,top: marges.margin_top + marges.l_h + 20, },
      headStyles: {
        halign: "left",
        valign: "middle",
        cellPadding:1,
        fillColor: [128, 128, 128],
      },
      head: [head],
      body: doc_head,
      columnStyles: {
        0: { halign: "left", cellWidth: 50 },        
        1: { halign: "left", cellWidth: "auto",},
      },
      columns: columns,
      theme: "striped",
      styles: {
        valign: "middle",      
        fillColor: [255, 255, 255],        
        cellPadding: 1,
        fontSize: 8
      },
      tableWidth: 90,
    }); 
    //Taxes 
    columns = [];
    head= [];
    let taxe_headers= [ 
      {
        text: "Taxe",
        value: "libelle",
        selected: true,
        align: "left",
        width: "auto",
      },      
      {
        text: "Montant",
        value: "mont_ht",
        slot: "cur",
        width: 30,
        align: "end",
        selected: true,
      },
    ]
     item;
     items = [];
     //data formating
     for (let index = 0; index < taxe_list.length; index++) {
      item = {};
      taxe_headers.forEach((elm) => {
        if (elm.value == "no") {
          item[elm.value] = index + 1;
        }
        if (elm.slot == "cur") {
          item[elm.value] = numberWithSpace(
            parseFloat(taxe_list[index][elm.value]).toFixed(2)
          );          
        } else if (taxe_list[index][elm.value])
          item[elm.value] = taxe_list[index][elm.value];
      });      
      items.push(item);
    }
    item = {};   
    columnStyles = {};
    taxe_headers.forEach((element) => {
      head.push({
        content: element.text,
        styles: {
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
        },
      });

      columns.push({ dataKey: element.value });
      columnStyles[element.value] = {
        cellWidth: element.width,
        valign: "middle",
        halign:
          element.align == "end"
            ? "right"
            : element.align == "center"
            ? "center"
            : "left",
      };
    });

    doc.autoTable({
      margin: {
       left: marges.margin_left+92,              
        bottom: marges.margin_bottom + marges.p_h,
      },
      startY:55,       
      headStyles: {
        halign: "center",
        valign: "middle",
        cellPadding:1,
        fillColor: [128, 128, 128],
        textColor: [255, 255, 255],
      },
      columnStyles: columnStyles,
      head: [head],
      body: items,
      columns: columns,
      showHead: "everyPage",
      showFoot: "everyPage",
      styles: {
       valign: "middle",      
       fillColor: [255, 255, 255],        
       cellPadding: 1,
       fontSize: 8
     },
      theme: "striped",
    });       
     //Frais 
     columns = [];
     head= [];
     let approche_headers= [ 
       {
         text: "Frais d'approche",
         value: "libelle",
         selected: true,
         align: "left",
         width: "auto",
       },      
       {
         text: "Montant",
         value: "mont_ht",
         slot: "cur",
         width: 30,
         align: "end",
         selected: true,
       },
     ]
     let item;
     let items = [];
      //data formating
      for (let index = 0; index < approche_list.length; index++) {
       item = {};
       approche_headers.forEach((elm) => {
         if (elm.value == "no") {
           item[elm.value] = index + 1;
         }
         if (elm.slot == "cur") {
           item[elm.value] = numberWithSpace(
             parseFloat(approche_list[index][elm.value]).toFixed(2)
           );          
         } else if (approche_list[index][elm.value])
           item[elm.value] = approche_list[index][elm.value];
       });      
       items.push(item);
     }
     item = {};   
     let columnStyles = {};
     approche_headers.forEach((element) => {
       head.push({
         content: element.text,
         styles: {
           halign:
             element.align == "end"
               ? "right"
               : element.align == "center"
               ? "center"
               : "left",
         },
       });
 
       columns.push({ dataKey: element.value });
       columnStyles[element.value] = {
         cellWidth: element.width,
         valign: "middle",
         halign:
           element.align == "end"
             ? "right"
             : element.align == "center"
             ? "center"
             : "left",
       };
     });
 
     doc.autoTable({
       margin: {
        left: marges.margin_left+92,              
         bottom: marges.margin_bottom + marges.p_h,
       },            
       headStyles: {
         halign: "center",
         valign: "middle",
         cellPadding:1,
         fillColor: [128, 128, 128],
         textColor: [255, 255, 255],
       },
       columnStyles: columnStyles,
       head: [head],
       body: items,
       columns: columns,
       showHead: "everyPage",
       showFoot: "everyPage",
       styles: {
        valign: "middle",      
        fillColor: [255, 255, 255],        
        cellPadding: 1,
        fontSize: 8
      },
       theme: "striped",
     });       
    doc.setProperties({ title: "Dossier Importation" });
    doc.output("pdfobjectnewwindow");
}