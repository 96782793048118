<template>
  <v-theme-provider light>
    <v-card
      v-if="editedItem.id > 0 || editedItem.id == -1"
      elevation="6"
      :key="kdoc"
    >
      <!-- Menu + Alert ------------------------------------------------------------->
      <v-toolbar dense floating class="mt-n2 ml-n2" color="#73DDFF">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="valider_dossier"
              v-if="
                valider &&
                editedItem.statut_id == '28' &&
                detail_list.length > 0 &&
                !edit
              "
              :disabled="!editedItem.num_BE || !editedItem.date_BE"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-check-bold </v-icon>
              Cloturer
            </v-btn>
          </template>
          <span>Cloturer Dossier</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="solder_dossier"
              v-if="
                valider &&
                editedItem.statut_id == '29' &&
                detail_list.length > 0 &&
                !edit
              "
              :disabled="!editedItem.num_BE || !editedItem.date_BE"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-cancel </v-icon>
              Solder
            </v-btn>
          </template>
          <span>Solder Dossier</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="deleteDossier"
              v-if="
                editer &&
                editedItem.statut_id == '28' &&
                detail_list.length == 0 &&
                !edit
              "
              :disabled="editedItem.nbdocs.length > 0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-delete </v-icon>
              Supprimer
            </v-btn>
          </template>
          <span>Supprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="modifier"
              v-if="editer && editedItem.statut_id == '28' && !edit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-pencil </v-icon>
              Modifier
            </v-btn>
          </template>
          <span>Editer pour modification</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="cancel"
              v-if="editer && edit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-close </v-icon> Annuler
            </v-btn>
          </template>
          <span>Annuler les Modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="save"
              v-if="editer && edit"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-content-save-outline </v-icon>
              Enregistrer
            </v-btn>
          </template>
          <span>Enregistrer les modifications</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text @click="printDossier" v-bind="attrs" v-on="on">
              <v-icon> mdi-printer </v-icon>
            </v-btn>
          </template>
          <span>Imprimer</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01061') || $store.state.isadmin)
              "
              @click="OpenDocList"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-paperclip </v-icon>
            </v-btn>
          </template>
          <span>Documents Attachés</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              @click="unlock"
              v-if="
                ($store.state.auth.includes('01068') || $store.state.isadmin) &&
                editedItem.statut_id == '29' &&
                !edit
              "
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-lock-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Déverrouiller</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-if="
                editedItem.id > 0 &&
                ($store.state.auth.includes('01060') || $store.state.isadmin)
              "
              @click.stop="drawer = !drawer"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-clipboard-text-clock-outline </v-icon>
            </v-btn>
          </template>
          <span>Historique Document</span>
        </v-tooltip>
      </v-toolbar>

      <v-progress-linear
        v-if="progress || data_loading"
        indeterminate
        height="10"
        color="light-green darken-4"
        striped
      ></v-progress-linear>
      <!-- -------------------------------------------------------------------------->
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation class="px-4 mt-6">
          <!-- Form Title --------------------------------------------------------------->
          <v-row>
            <v-col cols="12" sd="12" md="4" lg="4">
              <v-chip color="black" label text-color="white" large>
                <h3>{{ formTitle }}</h3>
              </v-chip>
            </v-col>
            <v-col cols="12" sd="8" md="8">
              <v-alert
                v-model="alert"
                :type="type_alert"
                class="multi-line"
                width="380"
                elevation="4"
                >{{ message }}</v-alert
              >
            </v-col>
            <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit"
              ><strong>
                Date : {{ datefr(editedItem.date_dossier) }}</strong
              ></v-col
            >
          </v-row>
          <!-- Header Info -------------------------------------------------------------->
          <v-row>
            <v-col cols="12" sd="6" md="6" lg="6">
              <v-row>
                <v-col cols="12" sd="12" md="12" lg="12" v-if="edit">
                  <cursorselect
                    :Qsearch="tierscursor"
                    :Qresp="'tiers_cursor'"
                    :value="editedItem.tier_id"
                    :text_fields="['code_fournisseur', 'nom']"
                    :variableadd="variableadd"
                    :whereadd="whereadd"
                    @change="tierChange"
                    :label="'Fournisseur'"
                    :rules="true"
                    :readonly="
                      !modify || editedItem.details.length > 0 || !edit
                    "
                  >
                  </cursorselect>
                </v-col>
                <v-col cols="12" sd="12" md="12" lg="12" v-if="edit">
                  <v-autocomplete
                    :items="cmds"
                    v-model="editedItem.cmd_id"
                    :item-text="
                      (item) =>
                        item.contrat_type +
                        ' ' +
                        item.nocmd +
                        (item.cmd_ref ? ' (' + item.cmd_ref + ')' : '') +
                        ' - ' +
                        (item.label ? item.label : item.tier_name)
                    "
                    item-value="id"
                    :label="'Commande/Contrat'"
                    :rules="[(v) => !!v || 'Commande ou Contrat obligatoire']"
                    :readonly="
                      !modify || editedItem.details.length > 0 || !edit
                    "
                    @change="cmd_change"
                    dense
                    small-chips
                    :loading="cmds_load"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit">
                  <h3>Fournisseur : {{ editedItem.tier_name }}</h3>
                </v-col>
                <v-col cols="12" sd="12" md="12" lg="12" v-if="!edit">
                  <h3>
                    Commande/Contrat N° :
                    {{
                      editedItem.nocmd +
                      (editedItem.cmd_ref
                        ? "-(" + editedItem.cmd_ref + ")"
                        : "") +
                      (editedItem.dossier_label
                        ? "-" + editedItem.dossier_label
                        : "")
                    }}
                  </h3>
                </v-col>
                <v-col cols="12" sd="8" md="8" lg="8">
                  <v-autocomplete
                    :items="countrys_list"
                    :item-value="'id'"
                    item-text="label"
                    dense
                    v-model="editedItem.country_origine_id"
                    :label="'Pays d\'origine'"
                    :readonly="!edit"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sd="8" md="8" lg="8">
                  <v-autocomplete
                    :items="agences_tier"
                    item-value="id"
                    item-text="agence"
                    v-model="editedItem.bank_fournis_id"
                    label="Banque fournisseur"
                    :readonly="!edit"
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sd="8" md="8" lg="8">
                  <v-text-field
                    dense
                    v-model="editedItem.comment"
                    label="Commentaire"
                    :readonly="!edit"
                    @input="cmpt++"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sd="6" md="6" lg="6">
              <v-row>
                <v-col cols="12" sd="6" md="6" lg="6">
                  <v-row v-if="!edit" no-gutters>
                    <v-col cols="12" sd="6" md="12" lg="12">
                      Montant Dossier:
                    </v-col>
                    <v-col
                      cols="12"
                      sd="6"
                      md="12"
                      lg="12"
                      :class="
                        editedItem.montant != grandTotal.toFixed(2)
                          ? 'red--text'
                          : ''
                      "
                    >
                      <h3>
                        {{
                          numberWithSpace(
                            decimalDigits(editedItem.montant) +
                              " " +
                              (editedItem.devise ? editedItem.devise : "")
                          )
                        }}
                      </h3>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  Condition :
                  <h3>
                    {{ editedItem.incoterme ? editedItem.incoterme : "" }}
                  </h3>
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  {{
                    "Mode Paiement : " +
                    (editedItem.modepaiement ? editedItem.modepaiement : "")
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  Modalité de paiment :
                  {{ editedItem.modalite }}
                </v-col>
                <v-col cols="12" sd="12" md="12" lg="12">
                  {{
                    "Mode de Transport : " +
                    (editedItem.transport ? editedItem.transport : "")
                  }}
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  <v-autocomplete
                    :items="ports_etr"
                    :item-value="'id'"
                    item-text="libelle"
                    dense
                    v-model="editedItem.lieu_embar"
                    :label="'Lieu d\'embarquement'"
                    :rules="[(v) => !!v || 'Lieu d\'embarquement obligatoire']"
                    :readonly="!edit"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  <v-autocomplete
                    :items="ports_loc"
                    :item-value="'id'"
                    item-text="libelle"
                    dense
                    v-model="editedItem.lieu_debarq"
                    :label="'Lieu de débarquement'"
                    :rules="[(v) => !!v || 'Lieu de débarquement obligatoire']"
                    :readonly="!edit"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  <v-text-field
                    dense
                    v-model="editedItem.num_paiement"
                    label="SWIFT"
                    :readonly="!edit"
                    @input="cmpt++"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sd="6" md="6" lg="6">
                  <datepicker
                    label="Date SWIFT"
                    v-model="editedItem.date_paiement"
                    :edit="edit"
                    :rules="[
                      (v) =>
                        !!v || !editedItem.num_paiement || 'Date obligatoire',
                    ]"
                  ></datepicker>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Tabs --------------------------------------------------------------------->
          <v-row>
            <v-col cols="12" sd="12" md="12" v-if="editedItem.id > 0">
              <v-card elevation="3">
                <v-tabs
                  v-model="tabs"
                  left
                  background-color="#73DDFF"
                  color="black"
                >
                  <v-tab href="#1" key="1"> Factures</v-tab>
                  <v-tab href="#2" key="2"> Produits</v-tab>
                  <v-tab href="#3" key="3"> Domiciliation </v-tab>
                  <v-tab href="#4" key="4"> Frêt </v-tab>
                  <v-tab
                    href="#5"
                    key="5"
                    v-if="
                      editedItem.id > 0 &&
                      editedItem.num_dom &&
                      is_date(editedItem.date_dom)
                    "
                  >
                    Transit
                  </v-tab>
                  <v-tab
                    href="#6"
                    key="6"
                    v-if="
                      editedItem.id > 0 &&
                      is_date(editedItem.date_remise_transit)
                    "
                  >
                    Droits et Taxes
                  </v-tab>
                  <v-tab
                    href="#7"
                    key="7"
                    v-if="
                      editedItem.id > 0 &&
                      is_date(editedItem.date_remise_transit)
                    "
                  >
                    Frais d'approches
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                  <v-tab-item :value="'1'">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sd="12" md="12">
                            <listitems
                              :list="factures_list"
                              :qCreate="Qfacturecreate"
                              :qUpdate="Qfactureupdate"
                              :qDelete="Qfacturedelete"
                              :headers="factures_headers"
                              :Get_suf="'DossierImportFacture'"
                              :filename="'Factures'"
                              :sheetname="'Factures'"
                              :master="true"
                              :showstd="true"
                              :Total="true"
                              :key="klistfact"
                              :add="editer && editedItem.statut_id == '28'"
                              :Update="editer && editedItem.statut_id == '28'"
                              :del="editer && editedItem.statut_id == '28'"
                              :elevation="0"
                              @add="facture_update"
                              @change="facture_update"
                              @delete="facture_update"
                            >
                            </listitems>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sd="12" md="12">
                            <listitems
                              :list="detail_list"
                              :headers="products_headers"
                              :filename="'Produits'"
                              :sheetname="'Produits'"
                              @open="OpenProductForm"
                              @btn_click="OpenProductForm"
                              :master="true"
                              :showedit="false"
                              :key="klist"
                              :add="
                                editer &&
                                editedItem.statut_id == '28' &&
                                editedItem.id > 0
                              "
                              :Update="
                                editer &&
                                editedItem.statut_id == '28' &&
                                editedItem.id > 0
                              "
                              :del="
                                editer &&
                                editedItem.statut_id == '28' &&
                                editedItem.id > 0
                              "
                              :elevation="0"
                              @delete="deleteitem"
                            >
                            </listitems>
                          </v-col>
                        </v-row>

                        <v-row no-gutters v-if="!edit">
                          <v-col cols="12" sd="6" md="8"> </v-col>
                          <v-col cols="12" sd="6" md="4" class="px-3">
                            <v-row no-gutters v-if="editedItem.fret_devise">
                              <v-col cols="12" sd="6" md="4">
                                {{
                                  editedItem.fret_inclu
                                    ? "Frêt (inclu)"
                                    : "Frêt (non inclu)"
                                }}
                              </v-col>
                              <v-col
                                cols="12"
                                sd="6"
                                md="8"
                                class="text-bold nowrap text-right pa-1"
                              >
                                {{
                                  numberWithSpace(
                                    decimalDigits(editedItem.fret_devise)
                                  )
                                }}
                              </v-col>
                            </v-row>
                            <!-- <v-row no-gutters>
                                    <v-col cols="12" sd="6" md="4">
                                      Total
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sd="6"
                                      md="8"
                                      class="text-bold nowrap text-right pa-1"
                                    >
                                      {{
                                        numberWithSpace(decimalDigits(subTotal))
                                      }}
                                    </v-col>
                                  </v-row> -->

                            <v-row no-gutters>
                              <v-col cols="12" sd="6" md="4"
                                >Total {{ "(" + editedItem.devise + ")" }}
                              </v-col>
                              <v-col
                                cols="12"
                                sd="6"
                                md="8"
                                class="text-bold nowrap text-right pa-1"
                              >
                                {{ numberWithSpace(decimalDigits(grandTotal)) }}
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'3'">
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <v-autocomplete
                                :items="agences_list"
                                :item-value="'id'"
                                item-text="agence"
                                dense
                                v-model="editedItem.bank_dom"
                                :label="'Banque'"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    !editedItem.num_predom ||
                                    'Banque obligatoire',
                                ]"
                                :readonly="!edit"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_predom"
                                label="Numéro Pre-Domiciliation"
                                :readonly="!edit"
                                :disabled="!editedItem.bank_dom"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Pre-Domiciliation"
                                v-model="editedItem.date_predom"
                                :edit="edit"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    !editedItem.num_predom ||
                                    'Date obligatoire',
                                ]"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sm="6" md="4"> </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_dom"
                                label="Numéro Domiciliation"
                                :disabled="!editedItem.num_predom"
                                :readonly="!edit"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Domiciliation"
                                v-model="editedItem.date_dom"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    !editedItem.num_dom ||
                                    'Date obligatoire',
                                ]"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'4'">
                    <v-card>
                      <v-card-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date d'embarquement prevu"
                                v-model="editedItem.date_embar_prev"
                                :edit="edit"
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    'Date d\'embarquement prevu obligatoire',
                                ]"
                                :date_max="null"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date réelle d'embarquement"
                                v-model="editedItem.date_embar"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sd="3" md="3">
                              <v-text-field
                                autocomplete="off"
                                dense
                                type="number"
                                class="inputPrice"
                                v-model.number="editedItem.fret_devise"
                                :label="
                                  'Montant Frêt ' +
                                  (editedItem.devise
                                    ? '(' + editedItem.devise + ')'
                                    : '')
                                "
                                :readonly="!edit"
                                v-if="edit && !editedItem.num_D10"
                                @input="cmpt++"
                                :disabled="editedItem.fret_dzd > 0"
                                hide-spin-buttons
                                @focus="$event.target.select()"
                              ></v-text-field>
                              <span v-if="!edit">
                                {{
                                  "Montant Frêt: " +
                                  numberWithSpace(
                                    decimalDigits(editedItem.fret_devise) +
                                      " " +
                                      editedItem.devise
                                  )
                                }}
                              </span>
                            </v-col>
                            <v-col cols="12" sm="1" md="1">
                              <v-checkbox
                                v-model="editedItem.fret_inclu"
                                label="Frêt inclu "
                                :readonly="!edit"
                                class="pa-1"
                                dense
                                @change="cmpt++"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-autocomplete
                                :items="compagnies"
                                :item-value="'id'"
                                item-text="nom"
                                dense
                                v-model="editedItem.company_id"
                                :label="'Compagnie de Transport'"
                                :readonly="!edit"
                                :disabled="!is_date(editedItem.date_embar)"
                              ></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_doc_transport"
                                label="Document de transport"
                                :readonly="!edit"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.navire"
                                label="Navire"
                                :readonly="!edit"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="ETA"
                                v-model="editedItem.eta"
                                :edit="edit"
                                :date_max="null"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Débarquement"
                                v-model="editedItem.date_debarq"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-subtitle>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'5'">
                    <v-card>
                      <v-card-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Remise Dossier"
                                v-model="editedItem.date_remise_transit"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <v-autocomplete
                                :items="transitaires"
                                :item-value="'id'"
                                item-text="nom"
                                dense
                                v-model="editedItem.transit_id"
                                :label="'Transitaire'"
                                :readonly="!edit"
                                :disabled="!editedItem.date_remise_transit"
                              ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" sd="6" md="4"> </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_D10"
                                label="Numéro D10"
                                :readonly="!edit"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date D10"
                                v-model="editedItem.date_D10"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sd="6" md="4"> </v-col>
                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_franchise"
                                label="Numéro Franchise"
                                :readonly="!edit"
                                @input="taux_change"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Franchise"
                                v-model="editedItem.date_franchise"
                                :edit="edit"
                                :disabled="!editedItem.num_franchise"
                              ></datepicker>
                            </v-col>
                            <v-col cols="12" sd="6" md="4"> </v-col>

                            <v-col cols="12" sd="6" md="4">
                              <v-text-field
                                autocomplete="off"
                                dense
                                class="inputPrice"
                                v-model="editedItem.num_BE"
                                label="Numéro Bon d'enlevement"
                                :readonly="!edit"
                                @input="cmpt++"
                                @focus="$event.target.select()"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                              <datepicker
                                label="Date Bon d'enlevement"
                                v-model="editedItem.date_BE"
                                :edit="edit"
                              ></datepicker>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-subtitle>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'6'">
                    <v-card>
                      <v-card-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sd="6" md="4">
                              <v-row>
                                <v-col cols="12" sd="6" md="6">
                                  <v-text-field
                                    autocomplete="off"
                                    type="number"
                                    dense
                                    class="inputPrice"
                                    v-model.number="editedItem.taux_change"
                                    label="Taux de change"
                                    :readonly="!edit"
                                    @input="taux_change"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sd="6" md="12">
                                  <v-text-field
                                    autocomplete="off"
                                    type="number"
                                    dense
                                    class="inputPrice"
                                    v-model.number="editedItem.fret_dzd"
                                    label="Frêt HT (DZD)"
                                    :readonly="!edit"
                                    @input="cmpt++"
                                    :disabled="editedItem.fret_devise > 0"
                                    hide-spin-buttons
                                    v-if="edit"
                                    @focus="$event.target.select()"
                                  ></v-text-field>
                                  <span v-if="!edit">
                                    {{
                                      "Montant Frêt HT(DZD): " +
                                      numberWithSpace(
                                        decimalDigits(editedItem.fret_dzd)
                                      )
                                    }}
                                  </span>
                                </v-col>
                                <v-col cols="12" sd="6" md="12">
                                  <span>
                                    {{
                                      "Valeur Marchandise : " +
                                      numberWithSpace(
                                        decimalDigits(
                                          Math.trunc(
                                            editedItem.montant *
                                              (editedItem.taux_change
                                                ? editedItem.taux_change
                                                : 0) +
                                              editedItem.fret_dzd
                                          )
                                        ) + " DZD"
                                      )
                                    }}
                                  </span>
                                </v-col>

                                <v-col cols="12" sd="6" md="12">
                                  <span>
                                    {{
                                      "TVA : " +
                                      numberWithSpace(decimalDigits(tvaD10))
                                    }}
                                  </span>
                                </v-col>
                                <v-col cols="12" sd="6" md="12">
                                  <span>
                                    <h6>
                                      <strong>
                                        {{
                                          "Montant Droit & Taxe : " +
                                          numberWithSpace(
                                            decimalDigits(sumtaxTotal + tvaD10)
                                          )
                                        }}
                                      </strong>
                                    </h6>
                                  </span>
                                </v-col>
                                <v-col cols="12" sd="6" md="6">
                                  <v-text-field
                                    autocomplete="off"
                                    dense
                                    v-model="editedItem.num_doc_paiement"
                                    label="Numéro Pièce"
                                    :readonly="!edit"
                                    @input="cmpt++"
                                    :disabled="!editedItem.date_remise_transit"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                  <datepicker
                                    label="Date Pièce"
                                    v-model="editedItem.date_doc_paiement"
                                    :edit="edit"
                                    :disabled="!editedItem.num_doc_paiement"
                                  ></datepicker>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" sd="6" md="8">
                              <listitems
                                :list="taxes"
                                :qDelete="Qapprochedelete"
                                :qCreate="Qapprochecreate"
                                :qUpdate="Qapprocheupdate"
                                :headers="taxes_headers"
                                :Get_suf="'DossierImportApproche'"
                                :filename="'Taxes'"
                                :sheetname="'Taxes'"
                                :Total="true"
                                :master="false"
                                :showstd="true"
                                :key="ktaxe"
                                :add="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :Update="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :del="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :elevation="0"
                                @add="tax_update"
                                @delete="tax_update"
                                @change="tax_update"
                              >
                              </listitems>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-subtitle>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :value="'7'">
                    <v-card>
                      <v-card-subtitle>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sd="6" md="12">
                              <listitems
                                :list="frais_approches"
                                :qDelete="Qapprochedelete"
                                :qCreate="Qapprochecreate"
                                :qUpdate="Qapprocheupdate"
                                :headers="approche_headers"
                                :Get_suf="'DossierImportApproche'"
                                :filename="'Approche'"
                                :sheetname="'Approche'"
                                :master="true"
                                :showstd="true"
                                :Total="true"
                                :key="kapp"
                                :add="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :Update="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :del="
                                  editer &&
                                  editedItem.statut_id == '28' &&
                                  editedItem.id > 0
                                "
                                :elevation="0"
                                @add="appr_update"
                                @change="appr_update"
                                @delete="appr_update"
                              >
                              </listitems>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-subtitle>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-col>
          </v-row>
        </v-form>

        <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />

        <v-snackbar
          v-model="snackbar"
          :timeout="snackbar_timeout"
          top
          :color="snackbar_color"
        >
          {{ snackbar_text }}
        </v-snackbar>

        <dossierdetailform
          :item="product"
          :items="detail_list"
          :cmd="cmd"
          :cmddetails="cmddetails"
          :readonly="editedItem.statut_id != '28'"
          :key="dkey"
          :showForm="!isDetailClosed"
          @close="closeDetailForm"
          :dossier="editedItem"
        >
        </dossierdetailform>

        <filelist
          :item="editedItem"
          :showform="!isDocListClose"
          :key="fdocl"
          @close="isDocListClose = true"
          :editer="editer"
          :doc_type="10"
          :auth="'01069'"
        >
        </filelist>
        <timeline
          :document_type="11"
          :document_id="parseInt(editedItem.id)"
          v-model="drawer"
          :operations="operations"
          :doc_type="'Dossier'"
          :doc_code="editedItem.nodossier"
        >
        </timeline>
      </v-card-text>
    </v-card>
  </v-theme-provider>
</template>
<script>
import TIERS_CURSOR from "../graphql/Commande/TIERS_CURSOR.gql";
import DELETE_DOSSIER_DETAIL from "../graphql/Import/DELETE_DOSSIER_DETAIL.gql";
import CREATE_DOSSIER_FACTURE from "../graphql/Import/CREATE_DOSSIER_FACTURE.gql";
import UPDATE_DOSSIER_FACTURE from "../graphql/Import/UPDATE_DOSSIER_FACTURE.gql";
import DELETE_DOSSIER_FACTURE from "../graphql/Import/DELETE_DOSSIER_FACTURE.gql";
import DELETE_DOSSIER_APPROCHE from "../graphql/Import/DELETE_DOSSIER_APPROCHE.gql";
import DELETE_DOSSIER from "../graphql/Import/DELETE_DOSSIER.gql";
import CREATE_DOSSIER from "../graphql/Import/CREATE_DOSSIER.gql";
import CREATE_DOSSIER_APPROCHE from "../graphql/Import/CREATE_DOSSIER_APPROCHE.gql";
import UPDATE_DOSSIER from "../graphql/Import/UPDATE_DOSSIER.gql";
import UPDATE_DOSSIER_APPROCHE from "../graphql/Import/UPDATE_DOSSIER_APPROCHE.gql";
import INSERT_DOSSIER_DETAIL from "../graphql/Import/INSERT_DOSSIER_DETAIL.gql";
import UPDATE_MODIF from "../graphql/UPDATE_MODIF.gql";
import ACTUAL_CMDS from "../graphql/Mouvment/ACTUAL_CMDS.gql";
import { dossier } from "print/dossier.js";
import { datefr } from "@/utils/functions.js";
//import { generatePrint } from "print/ettiquette.js";

import { myLogo, myName, myIF, myRC, myART } from "print/data.js";

export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    dossierdetailform: () => import("./DossierDetailForm.vue"),
    filelist: () => import("./FileList.vue"),
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
    timeline: () => import("./Timeline.vue"),
    cursorselect: () => import("./CursorSelect.vue"),
  },
  props: {
    dossier: Object,
    agences_list: Array,
    tiers_agences: Array,
    countrys: Array,
    data_loading: Boolean,
    tier_list: Array,
    approches_list: Array,
    ports: Array,
    editer: Boolean,
    valider: Boolean,
    edition: { type: Boolean, default: false },
  },
  data() {
    return {
      tabs: null,
      is_motif: false,
      message: "",
      alert: false,
      type_alert: "error",
      drawer: null,
      modify: false,
      progress: false,
      duedate: null,
      valid: true,
      numberRule: (v) => {
        if (!v || (!isNaN(parseFloat(v)) && v >= 0 && v <= 100)) return true;
        return "Nombre doit être entre 0 and 100";
      },
      act_save: true,
      isDetailClosed: true,
      isProdClosed: true,
      isDocListClose: true,
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",

      client: "Client information",
      mc: 200,
      cmd_list: [],
      dkey: 100,
      pkey: 300,
      fdocl: 1000,
      dossierdetail: {},
      dossiers_list: [],
      isOrderClosed: true,
      order: {},
      changed: false,
      korder: 0,
      product: {},
      approche: {},
      factures_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "N° Facture",
          value: "num_facture",
          selected: true,
          edit: true,
          rules: [(v) => !!v || "N° Facture obligatoire"],
          type: "text",
          dense: true,
          sm: 4,
          md: 4,
        },
        {
          text: "Date Facture",
          value: "date_facture",
          selected: true,
          slot: "date",
          edit: true,
          rules: [(v) => !!v || "Date facture obligatoire"],
          type: "date",
          sm: 4,
          md: 4,
        },
        {
          text: "Montant Facture",
          value: "montant",
          slot: "cur",
          align: "end",
          width: "120px",
          total: "montant",
          selected: true,
          rules: [(v) => !!v || "Montant obligatoire"],
          edit: true,
          type: "number",
          dense: true,
          sm: 4,
          md: 4,
        },

        {
          text: "Comment",
          value: "comment",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
        {
          text: "dossier_id",
          value: "dossier_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      products_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          align: "left",
          width: "140px",
        },
        {
          text: "Produit",
          value: "label",
          slot: "href99",
          selected: true,
          align: "left",
        },
        {
          text: "PU",
          value: "pu",
          width: "100px",
          selected: true,
        },
        {
          text: "PU(DZD)",
          value: "pu_calc",
          slot: "cur",
          selected: true,
          width: "100px",
          align: "end",
        },
        {
          text: "Quantité",
          value: "qte",
          width: "120px",
          slot: "cur",
          align: "right",
          hoover: true,
          selected: true,
        },
        {
          text: "Montant",
          value: "montant",
          slot: "cur",
          align: "end",
          width: "180px",
          selected: true,
        },
        {
          text: "Rcpt",
          value: "qte_rcpt",
          slot: "cur",
          width: "120px",
          align: "end",
          hoover_list: true,
          selected: false,
        },
        {
          text: "Reste",
          value: "qte_rest",
          slot: "cur",
          width: "120px",
          align: "end",
          hoover_list: true,
          selected: false,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
        },
      ],
      approche_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },

        {
          text: "Libelle",
          value: "frais_id",
          selected: true,
          slot: "simple-select",
          rules: [(v) => !!v || "Libelle obligatoire"],
          edit: true,
          type: "select",
          item_value: "id",
          item_text: "libelle",
          select_list: [],
          show_list: [],
          totlib: true,
          sm: 12,
          md: 12,
        },

        {
          text: "Montant HT",
          value: "mont_ht",
          slot: "href22",
          align: "end",
          width: "120px",
          total: "mont_ht",
          selected: true,
          rules: [(v) => !!v || "Montant HT obligatoire"],
          edit: true,
          type: "number",
          sm: 6,
          md: 6,
        },
        {
          text: "TVA",
          value: "tva",
          slot: "cur",
          align: "end",
          selected: true,
          default: 19,
          type: "number",
          edit: true,
          sm: 3,
          md: 3,
        },
        {
          text: "TTC",
          value: "mont_ttc",
          slot: "cur",
          selected: true,
          total: "mont_ttc",
          align: "end",
        },
        {
          text: "N° Pièce",
          value: "num_doc_paiement",
          selected: true,
          edit: true,
          type: "text",
          dense: true,
          sm: 6,
          md: 6,
        },
        {
          text: "Date Pièce",
          value: "date_doc_paiement",
          selected: true,
          edit: true,
          type: "date",
          sm: 6,
          md: 6,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
        {
          text: "dossier_id",
          value: "dossier_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
        {
          text: "type",
          value: "0",
          hiden: true,
          selected: false,
        },
      ],
      taxes_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
          align: "center",
          width: 10,
        },

        {
          text: "Libelle",
          value: "frais_id",
          selected: true,
          slot: "simple-select",
          rules: [(v) => !!v || "Libelle obligatoire"],
          edit: true,
          type: "select",
          item_value: "id",
          item_text: "libelle",
          select_list: [],
          show_list: [],
          totlib: true,
          sm: 12,
          md: 12,
        },

        {
          text: "Montant HT",
          value: "mont_ht",
          slot: "href22",
          align: "end",
          total: "mont_ht",
          width: "120px",
          selected: true,
          edit: true,
          type: "number",
          sm: 6,
          md: 6,
        },
        {
          text: "Comment",
          value: "comment",
          selected: false,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
        {
          text: "dossier_id",
          value: "dossier_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      edit: false,
      editedItem: {},
      dossier_list: [],
      cmds: [],
      cmd: {},
      kdoc: 700,
      klist: 500,
      kapp: 600,
      ktaxe: 800,
      klistfact: 900,
      cmpt: 0,
      sumtaxTotal: 0,
      tvaD10: 0,
      cmds_load: false,
      defaultItem: {
        id: -1,
        status_name: "Brouillon",
        tier: {},
        statut_id: "28",
        details: [],
        approches: [],
        docs: [],
        notes: "",
      },
    };
  },
  created() {
    if (this.dossier.id) {
      this.edit = this.edition;
      if (this.dossier.id > 0) {
        this.editedItem = Object.assign({}, this.dossier);
        if (this.editedItem.statut_id == "30") {
          this.message =
            "Motif : " + (this.editedItem.motif ? this.editedItem.motif : "");
          this.alert = true;
        }

        this.taxes_headers[4].default = this.editedItem.id;
        this.approche_headers[8].default = this.editedItem.id;
        this.factures_headers[5].default = this.editedItem.id;
        this.editedItem.fret_inclu = this.editedItem.fret_inclu == 0;
        this.tierChange();
        if (this.editedItem.retard > 0) {
          this.message =
            this.editedItem.status_name.substring(4) +
            " en retard de " +
            this.editedItem.retard +
            " jours" +
            "\n";
          this.alert = true;
          this.type_alert = "error";
        }
        if (this.editedItem.retard_bank > 0) {
          this.message =
            this.message +
            this.editedItem.status_bank.substring(4) +
            " en retard de " +
            this.editedItem.retard_bank +
            " jours" +
            "\n";
          this.alert = true;
          this.type_alert = "error";
        }
        if (this.editedItem.retard_fret > 0) {
          this.message =
            this.message +
            this.editedItem.status_fret.substring(4) +
            " en retard de " +
            this.editedItem.retard_fret +
            " jours";
          this.alert = true;
          this.type_alert = "error";
        }
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        if (this.agences_list.length == 1)
          this.editedItem.bank_dom = this.agences_list[0].agence_id;

        if (this.dossier.tier_id > 0) {
          this.editedItem.tier_id = this.dossier.tier_id;
        }
      }
    }
    if (this.editedItem.statut_id != "28") this.products_headers[2].slot = null;
    if (this.editedItem.statut_id != "28")
      this.approche_headers[2].slot = "cur";
    if (this.editedItem.statut_id != "28") this.taxes_headers[2].slot = "cur";
    this.products_headers[3].text = "PU (" + this.editedItem.devise + ")";
    this.products_headers[6].text = "Montant (" + this.editedItem.devise + ")";
    this.appr_update();
    this.tax_update();
    this.taxes_headers[1].select_list = this.approches_list.filter(
      (elm) => elm.type >= "1"
    );
    this.taxes_headers[1].show_list = this.taxes_headers[1].select_list;
    this.approche_headers[1].select_list = this.approches_list.filter(
      (elm) => elm.type == "0"
    );
    this.approche_headers[1].show_list = this.approche_headers[1].select_list;
    this.modify = this.editer && this.editedItem.statut_id == "28";
  },
  watch: {
    taxes: {
      async handler() {
        await this.tax_update();
      },
    },
  },
  methods: {
    async create_detail_dossier(dossier_id, cmddetails) {
      // créer detail commande
      let l = [];
      for (
        let index = 0;
        index <
        cmddetails.filter((elm) => elm.rest_import > 0 && elm.article_id)
          .length;
        index++
      ) {
        const element = cmddetails[index];
        l.push({
          pu: element.pu,
          qte: parseFloat(element.rest_import),
          dossier_id: dossier_id,
          article_id: element.article_id,
          create_uid: this.$store.state.me.id,
          write_uid: this.$store.state.me.id,
        });
      }
      let v = { input: l, dossier_id: dossier_id };
      await this.maj(INSERT_DOSSIER_DETAIL, v);
    },
    formatDate(date) {
      if (!date) return null;
      let d = new Date(date).toISOString().substr(0, 10);
      const [year, month, day] = d.split("-");
      return `${day}/${month}/${year}`;
    },
    embrq_clear() {
      this.editedItem.date_embar = null;
      this.editedItem.company_id = null;
      this.editedItem.num_doc_transport = null;
      this.editedItem.navire = null;
      this.editedItem.eta = null;
    },
    is_date(d) {
      let ok = false;
      if (d) {
        var date = new Date(d);
        ok = date instanceof Date && !isNaN(date.valueOf());
      }
      return ok;
    },
    taux_change() {
      this.tax_update();
    },
    tax_update(item) {
      if (item) item.type = "1";
      this.sumtaxTotal = this.taxes.reduce(
        (a, b) => a + (b["mont_ht"] || 0),
        0
      );
      let sumtaxTotal = this.taxes
        .filter((elm) => elm.type != 2)
        .reduce((a, b) => a + (b["mont_ht"] || 0), 0);
      let b = (this.editedItem.montant * this.editedItem.taux_change).toFixed(
        2
      );
      if (!this.editedItem.num_franchise)
        this.tvaD10 = Math.trunc(
          (
            ((Math.trunc(b) + (sumtaxTotal ? sumtaxTotal : 0)) * 19) /
            100
          ).toFixed(2)
        );

      this.cmpt++;
    },
    appr_update(item) {
      if (item) {
        item.type = "0";
        item.mont_ttc =
          item.mont_ht +
          parseFloat(((item.mont_ht * item.tva) / 100).toFixed(2));
      }
    },
    cmd_change() {
      let i = this.cmds.findIndex((elm) => elm.id == this.editedItem.cmd_id);
      if (i >= 0) {
        this.cmd = this.cmds[i];

        if (this.editedItem.id < 0) {
          this.editedItem.fret_devise = this.cmd.mont_transport;
          this.editedItem.lieu_debarq = this.cmd.port_id;
          this.editedItem.transport = this.cmd.transport;
          this.editedItem.devise = this.cmd.devise;
          this.editedItem.incoterme = this.cmd.incoterme;
          this.editedItem.modepaiement = this.cmd.paiement;
          this.editedItem.modalite = this.cmd.modalite;
        }
      }
    },

    async modifier() {
      if (await this.verif_modif()) {
        this.edit = true;
        this.$emit("edition", true);
        this.kdoc++;
      }
    },
    async cancel() {
      let ok = true;
      if (
        (this.cmpt > 0 && this.editedItem.id < 0) ||
        (this.cmpt > 1 && this.editedItem.id > 0)
      ) {
        ok = false;
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Modification en-cours! Etês-vous sûr de vouloir annuler?"
          )
        ) {
          ok = true;
        }
      }
      if (ok) {
        if (this.editedItem.id < 0) {
          this.$emit("cancel", this.editedItem);
          this.editedItem.id = null;
        }
        await this.update_modif();
        this.edit = false;
        this.$emit("edition", false);
        this.kdoc++;
        this.$store.dispatch("Changed", true);
      } else this.$store.dispatch("Editing", false);
    },

    async deleteitem(item) {
      if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimmer cette ligne?",
            { color: "orange darken-3" }
          )
        ) {
          let r = await this.maj(DELETE_DOSSIER_DETAIL, { id: item.id });
          if (r) {
            this.detail_list.splice(item.index, 1);
            this.$store.dispatch("Changed", true);
            this.$store.dispatch("Editing", false);
            this.update_modif();
            this.kdoc++;
          }
        } else this.update_modif();
      }
    },
    async tierChange(item, l) {
      let list = [];
      if (l) list = l;
      if (this.editedItem.id < 0) {
        this.editedItem.tier_id = item;
        let i = list.findIndex((elm) => elm.id == this.editedItem.tier_id);
        this.editedItem.code_tier = null;
        this.editedItem.adresse = null;
        if (i >= 0) {
          this.editedItem.code_tier = list[i].code_fournisseur;
          this.editedItem.adresse = list[i].adresse;
        }
      }
      let v = {
        where: {
          OR: [
            {
              AND: [
                { column: "TIER_ID", value: this.editedItem.tier_id },
                { column: "CMD_TYPE", operator: "IN", value: [2, 4] },
                { column: "STATUT_ID", value: 7 },
                { column: "CURRENCY_ID", operator: "NEQ", value: 114 },
                { column: "ARR_REST", operator: "GT", value: 0 },
              ],
            },
            {
              column: "ID",
              value: this.editedItem.cmd_id,
            },
          ],
        },
      };
      this.cmds_load = true;
      let r = await this.requette(ACTUAL_CMDS, v, "no-cache");
      this.cmds_load = false;
      if (r) {
        this.cmds = r.allcmds;
      }
      if (this.cmd.bank_id) this.editedItem.bank_fournis_id = this.cmd.bank_id;
      else if (this.agences_tier.length == 1)
        this.editedItem.bank_fournis_id = this.agences_tier[0].id;
      else {
        let i = this.agences_tier.findIndex((elm) => elm.default_agence == 1);
        if (i >= 0) this.editedItem.bank_fournis_id = this.agences_tier[i].id;
      }
      if (this.cmds.length == 1 && this.editedItem.id < 0) {
        this.editedItem.cmd_id = this.cmds[0].id;
      }
      this.cmd_change();
      this.cmpt++;
    },

    datefr(date, time = false) {
      let t = "";
      let option = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      if (time)
        option = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
      if (date) {
        var result = new Date(date);
        t = result.toLocaleDateString("fr-FR", option);
      }
      return t;
    },
    async OpenProductForm(item) {
      if (await this.verif_modif()) {
        if (item.article_id || item.id < 0) {
          this.product = item;
          this.product.dossier_id = this.editedItem.id;
          this.dkey++;
          this.isDetailClosed = false;
        }
      }
    },

    OpenDocList() {
      this.isDocListClose = false;
      this.fdocl++;
    },
    async maj(query, v, refresh) {
      let r;
      this.loading = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) this.$store.dispatch("Changed", true);
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async verif_modif() {
      this.alert = false;

      let ok = false;
      let v = {
        table: "dossiers_import",
        statut_id: "28",
        id: this.editedItem.id,
      };
      this.progress = true;
      let r = await this.maj(UPDATE_MODIF, v);
      this.progress = false;
      if (r) {
        let d = JSON.parse(r.UpdateModif);
        if (d.modif == 1) {
          this.alert = true;
          this.message =
            "Document ouvert en modification par " +
            d.name +
            " depuis le " +
            datefr(d.modif_date, true);
          this.type_alert = "warning";
        } else if (d.modif == 2) {
          this.alert = true;
          this.message = "Document Validé!! Actualiser vos données ";
          this.type_alert = "error";
        } else if (d.modif == -1) {
          this.alert = true;
          this.message = "Document introuvable ";
          this.type_alert = "error";
        } else {
          this.$store.dispatch("Document", v);
          this.$store.dispatch("Editing", true);
          ok = true;
        }
      }
      return ok;
    },
    async update_modif() {
      this.$store.dispatch("Editing", false);
      if (this.editedItem.id > 0) {
        let v = {
          dossierimport: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
          },
        };
        await this.maj(UPDATE_DOSSIER, v);
      }
    },
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    async unlock() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir déverrouiller ce dossier?"
        )
      ) {
        this.editedItem.statut_id = 28;
        let v = {
          dossierimport: {
            id: this.editedItem.id,
            modif_by: null,
            modif_date: null,
            statut_id: this.editedItem.statut_id,
            write_uid: this.$store.state.me.id,
          },
        };
        await this.maj(UPDATE_DOSSIER, v, true);
      }
    },
    get_motif(m) {
      this.editedItem.motif = m;
    },
    async solder_dossier() {
      this.is_motif = true;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir clôturer ce dossier?"
        )
      ) {
        this.editedItem.statut_id = 30;
        let v = {
          dossierimport: {
            id: this.editedItem.id,
            motif: this.editedItem.motif,
            statut_id: this.editedItem.statut_id,
            comment: this.editedItem.comment,
            modif_by: null,
            modif_date: null,
            write_uid: this.$store.state.me.id,
          },
        };
        await this.maj(UPDATE_DOSSIER, v, true);
        this.$store.dispatch("Editing", false);
      }
      this.is_motif = false;
    },
    async valider_dossier() {
      if (await this.verif_modif()) {
        let ok = true;
        this.alert = false;

        if (this.editedItem.montant.toFixed(0) != this.grandTotal.toFixed(0)) {
          ok = false;
          this.message =
            "le montant de la marchandise est different du montant de la facture!!--verifier";
          this.type_alert = "error";
          this.alert = true;
          this.update_modif();
          this.tabs = "1";
        }
        if (ok)
          if (!this.editedItem.taux_change) {
            ok = false;
            this.message = "le taux de change obligatoire!!--verifier";
            this.type_alert = "error";
            this.alert = true;
            this.update_modif();
            this.tabs = "5";
          }
        if (ok) {
          //verifier taxe obligatoires
          let l = this.approches_list.filter(
            (elm) => elm.type == "1" && elm.obligatoire == "1"
          );
          for (let index = 0; index < l.length; index++) {
            const element = l[index];
            if (
              this.taxes.findIndex((elm) => elm.frais_id == element.id) == -1
            ) {
              ok = false;
              this.message =
                "la taxe " + element.libelle + " est obligatoire!!--verifier";
              this.type_alert = "error";
              this.alert = true;
              this.update_modif();
              this.tabs = "5";
              break;
            }
          }
        }
        if (ok)
          if (
            await this.$refs.confirm.open(
              "Confirmation",
              "Etês-vous sûr de vouloir clôturer ce dossier?"
            )
          ) {
            if (this.$refs.form.validate()) {
              this.editedItem.statut_id = 29;
              let v = {
                dossierimport: {
                  id: this.editedItem.id,
                  cmd_id: this.editedItem.cmd_id,
                  num_predom: this.editedItem.num_predom,
                  bank_fournis_id: this.editedItem.bank_fournis_id,
                  country_origine_id: this.editedItem.country_origine_id,
                  date_predom: this.editedItem.date_predom,
                  date_dossier: this.editedItem.date_dossier,
                  bank_dom: this.editedItem.bank_dom,
                  statut_id: this.editedItem.statut_id,
                  num_doc_transport: this.editedItem.num_doc_transport,
                  navire: this.editedItem.navire,
                  company_id: this.editedItem.company_id,
                  date_embar: this.editedItem.date_embar,
                  date_embar_prev: this.editedItem.date_embar_prev,
                  lieu_embar: this.editedItem.lieu_embar,
                  eta: this.editedItem.eta,
                  date_debarq: this.editedItem.date_debarq,
                  lieu_debarq: this.editedItem.lieu_debarq,
                  num_paiement: this.editedItem.num_paiement,
                  date_paiement: this.editedItem.date_paiement,
                  num_dom: this.editedItem.num_dom,
                  date_dom: this.editedItem.date_dom,
                  date_remise_transit: this.editedItem.date_remise_transit,
                  transit_id: this.editedItem.transit_id,
                  num_D10: this.editedItem.num_D10,
                  date_D10: this.editedItem.date_D10,
                  taux_change: parseFloat(this.editedItem.taux_change),
                  fret_devise: parseFloat(this.editedItem.fret_devise),
                  fret_inclu: this.editedItem.fret_inclu ? 0 : 1,
                  fret_dzd: parseFloat(this.editedItem.fret_dzd),
                  total_tva: this.editedItem.total_tva,
                  num_BE: this.editedItem.num_BE,
                  date_BE: this.editedItem.date_BE,
                  num_franchise: this.editedItem.num_franchise,
                  date_franchise: this.editedItem.date_franchise,
                  modif_by: null,
                  modif_date: null,
                  comment: this.editedItem.comment,
                  write_uid: this.$store.state.me.id,
                },
              };
              await this.maj(UPDATE_DOSSIER, v, true);
              this.$store.dispatch("Editing", false);
            }
          } else this.update_modif();
      }
    },
    facture_update() {
      this.$store.dispatch("Changed", true);
    },
    closeDetailForm() {
      this.update_modif();
      this.isDetailClosed = true;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async deleteDossier() {
      if (this.editedItem.id < 0) {
        this.editedItem.id = null;
        this.$emit("cancel", this.editedItem);
      } else if (await this.verif_modif()) {
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer ce dossier?"
          )
        ) {
          let v = {
            id: this.editedItem.id,
          };
          let r = await this.requette(DELETE_DOSSIER, v);
          if (r) {
            this.editedItem = { id: null };
            this.$store.dispatch("Changed", true);
          }
        } else this.update_modif();
      }
    },
    decimalDigits: function (value) {
      return typeof value == "number" ? value.toFixed(2) : 0;
    },

    printDossier() {
      //generatePrint([{ sampleIdLims: "125487" }], 3);
      dossier(this.$store, this.editedItem, this.taxes, this.frais_approches);
    },

    async save() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id > 0) {
          let v = {
            dossierimport: {
              id: this.editedItem.id,
              cmd_id: this.editedItem.cmd_id,
              num_predom: this.editedItem.num_predom,
              bank_fournis_id: this.editedItem.bank_fournis_id,
              country_origine_id: this.editedItem.country_origine_id,
              date_predom: this.editedItem.date_predom,
              date_dossier: this.editedItem.date_dossier,
              bank_dom: this.editedItem.bank_dom,
              statut_id: this.editedItem.statut_id,
              num_doc_transport: this.editedItem.num_doc_transport,
              navire: this.editedItem.navire,
              company_id: this.editedItem.company_id,
              date_embar: this.editedItem.date_embar,
              date_embar_prev: this.editedItem.date_embar_prev,
              lieu_embar: this.editedItem.lieu_embar,
              eta: this.editedItem.eta,
              date_debarq: this.editedItem.date_debarq,
              lieu_debarq: this.editedItem.lieu_debarq,
              num_paiement: this.editedItem.num_paiement,
              date_paiement: this.editedItem.date_paiement,
              num_dom: this.editedItem.num_dom,
              date_dom: this.editedItem.date_dom,
              date_remise_transit: this.editedItem.date_remise_transit,
              transit_id: this.editedItem.transit_id,
              num_D10: this.editedItem.num_D10,
              date_D10: this.editedItem.date_D10,
              taux_change: parseFloat(this.editedItem.taux_change),
              fret_devise: parseFloat(this.editedItem.fret_devise),
              fret_inclu: this.editedItem.fret_inclu ? 0 : 1,
              fret_dzd: parseFloat(this.editedItem.fret_dzd),
              total_tva: this.editedItem.total_tva,
              num_BE: this.editedItem.num_BE,
              date_BE: this.editedItem.date_BE,
              num_franchise: this.editedItem.num_franchise,
              date_franchise: this.editedItem.date_franchise,
              modif_by: null,
              modif_date: null,
              comment: this.editedItem.comment,
              write_uid: this.$store.state.me.id,
            },
          };
          await this.maj(UPDATE_DOSSIER, v, true);
        } else {
          let v = {
            dossierimport: {
              cmd_id: this.editedItem.cmd_id,
              num_predom: this.editedItem.num_predom,
              bank_fournis_id: this.editedItem.bank_fournis_id,
              country_origine_id: this.editedItem.country_origine_id,
              date_predom: this.editedItem.date_predom,
              date_dossier: this.$store.state.today,
              bank_dom: this.editedItem.bank_dom,
              statut_id: this.editedItem.statut_id,
              num_doc_transport: this.editedItem.num_doc_transport,
              navire: this.editedItem.navire,
              company_id: this.editedItem.company_id,
              date_embar: this.editedItem.date_embar,
              date_embar_prev: this.editedItem.date_embar_prev,
              lieu_embar: this.editedItem.lieu_embar,
              eta: this.editedItem.eta,
              date_debarq: this.editedItem.date_debarq,
              lieu_debarq: this.editedItem.lieu_debarq,
              num_paiement: this.editedItem.num_paiement,
              date_paiement: this.editedItem.date_paiement,
              num_dom: this.editedItem.num_dom,
              date_dom: this.editedItem.date_dom,
              date_remise_transit: this.editedItem.date_remise_transit,
              transit_id: this.editedItem.transit_id,
              num_D10: this.editedItem.num_D10,
              date_D10: this.editedItem.date_D10,
              taux_change: parseFloat(this.editedItem.taux_change),
              fret_devise: parseFloat(this.editedItem.fret_devise),
              fret_inclu: this.editedItem.fret_inclu ? 0 : 1,
              fret_dzd: parseFloat(this.editedItem.fret_dzd),
              total_tva: this.editedItem.total_tva,
              num_BE: this.editedItem.num_BE,
              date_BE: this.editedItem.date_BE,
              num_franchise: this.editedItem.num_franchise,
              date_franchise: this.editedItem.date_franchise,
              comment: this.editedItem.comment,

              create_uid: parseInt(this.$store.state.me.id),
              write_uid: parseInt(this.$store.state.me.id),
            },
          };
          let r = await this.maj(CREATE_DOSSIER, v);
          if (r) {
            this.editedItem.id = r.createDossierImport.id;
            if (this.cmd.cmd_type == 2) {
              await this.create_detail_dossier(
                this.editedItem.id,
                this.cmd.cmddetails
              );
            }
            this.$store.dispatch("Changed", true);
            this.$emit("change", this.editedItem);
          }
        }
        this.edit = false;
        this.$emit("edition", false);
        this.$store.dispatch("Editing", false);
        this.kdoc++;
      } else if (this.editedItem.date_embar) this.tabs = "3";
    },
  },
  computed: {
    variableadd() {
      let w = {
        TypeScope: 1,
        statut_id: [2, 3],
      };
      return w;
    },
    whereadd() {
      return [{ column: "CURRENCY_ID", operator: "NEQ", value: 114 }];
    },
    tierscursor() {
      return TIERS_CURSOR;
    },
    agences_tier() {
      let l = [];
      l = this.tiers_agences.filter(
        (elm) => elm.tier_id == this.editedItem.tier_id
      );
      return l;
    },
    ports_loc() {
      let l = [];
      l = this.ports.filter((elm) => elm.fk_pays == "13");
      return l;
    },
    ports_etr() {
      let l = [];
      l = this.ports.filter((elm) => elm.fk_pays != "13");
      return l;
    },
    countrys_list() {
      return this.countrys.filter((elm) => elm.id != 13);
    },
    transitaires() {
      let list = [];
      if (this.tier_list)
        list = this.tier_list.filter((elm) => elm.tier_type_id == "7");
      return list;
    },
    compagnies() {
      let list = [];
      if (this.tier_list)
        list = this.tier_list.filter((elm) => elm.tier_type_id == "8");
      return list;
    },

    cmddetails: function () {
      let l = [];
      l = this.dossier.cmddetails;
      return l;
    },

    formTitle() {
      return this.editedItem.id < 0 || !this.editedItem.nodossier
        ? "Nouveau Dossier "
        : "Dossier N° " + this.editedItem.nodossier;
    },

    logo() {
      return myLogo;
    },
    myname() {
      return myName;
    },
    Qdelete() {
      return DELETE_DOSSIER_DETAIL;
    },
    Qapprochecreate() {
      return CREATE_DOSSIER_APPROCHE;
    },
    Qapprocheupdate() {
      return UPDATE_DOSSIER_APPROCHE;
    },
    Qapprochedelete() {
      return DELETE_DOSSIER_APPROCHE;
    },
    Qfacturecreate() {
      return CREATE_DOSSIER_FACTURE;
    },
    Qfactureupdate() {
      return UPDATE_DOSSIER_FACTURE;
    },
    Qfacturedelete() {
      return DELETE_DOSSIER_FACTURE;
    },
    myrc() {
      return myRC;
    },
    myif() {
      return myIF;
    },
    myart() {
      return myART;
    },

    operations: function () {
      return this.editedItem.operations ? this.editedItem.operations : [];
    },

    detail_list: function () {
      return this.editedItem.details ? this.editedItem.details : [];
    },
    factures_list: function () {
      return this.editedItem.factures ? this.editedItem.factures : [];
    },
    frais_approches: function () {
      return this.editedItem.approches
        ? this.editedItem.approches.filter((elm) => elm.type == "0")
        : [];
    },
    taxes: function () {
      return this.editedItem.approches
        ? this.editedItem.approches.filter((elm) => elm.type >= "1")
        : [];
    },
    subTotal: function () {
      var total = this.detail_list
        ? this.detail_list.reduce(function (accumulator, item) {
            return accumulator + item.pu * item.qte;
          }, 0)
        : 0;
      return total;
    },

    ttc: function () {
      var total =
        this.subTotal +
        (!this.editedItem.fret_inclu ? this.editedItem.fret_devise : 0);
      return total;
    },

    grandTotal: function () {
      var total = this.ttc;
      return total;
    },
  },
};
</script>
